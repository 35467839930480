import { useEffect, useState } from "react";
import { makeMessageID } from "../utils/helper/getUpdateRoomData";
import useGetAdvisorInfo from "./useGetAdvisorInfo";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  useSaveChatPayloadMutation,
  useUpdateStatusByMulipleReviewIdsMutation,
} from "../app/api/chat";
import {
  setAdvisorMessage,
  setAdvisorTxtOrderMessage,
  setChatHeadPagination,
  setGetPubnubMessages,
  setGetReplyThisClientData,
  setIsMessageRepliedSuccessfully,
  setIsReplyThisClient,
  setIsSendingMessageIsSuccessfully,
  setLiveChatImageUrl,
} from "../features/chat/chatSlice";
import { ref, onValue, off, push, set } from "firebase/database";
import { db } from "../utils/helper/firebase";
import useLogout from "./useLogout";
import { getRemainingTime } from "../utils/helper/getRemainingTime";
import toast from "react-hot-toast";
import useTextOrderTimer from "./useTextOrderTimer";
export const useSendMessage = () => {
  const dispatch = useDispatch();
  const { stoptAllModalHandlerTimer } = useTextOrderTimer();
  const { handleLogout } = useLogout();
  const [saveChatPayload, { error }] = useSaveChatPayloadMutation();
  const [updateStatusByMulipleReviewIds] =
    useUpdateStatusByMulipleReviewIdsMutation();
  const { advisorInfoData } = useGetAdvisorInfo();
  const {
    userSessionData,
    advisorMessage,
    firebaseClientData,
    liveChatImageUrl,
    advisorTxtOrderMessage,
    isReplyThisClient,
    getReplyThisClientData,
    chatBodyLiveFilteredData,
    chatHeadSelectedUser,
  } = useSelector((state) => state.chat);

  if (error) {
    if (error.status === 401) {
      handleLogout();
    }
  }
  if (!advisorInfoData?.user) {
    return {};
  }

  const { session_status, session_id } = firebaseClientData;
  const { client_displayname, client_id } = userSessionData;
  const { id, username } = advisorInfoData?.user;
  const { message_type } = getReplyThisClientData;

  const advisorMessageToFirebase = {
    app_version: "1.0.0",
    device_type: "web",
    auth_key: process.env.REACT_APP_AUTH_KEY,
    client_gender: "",
    create_transaction: "1",
    email_notification_enabled: "1",
    message_review_id: "w_".concat(makeMessageID(21)),
    message_type: liveChatImageUrl !== null ? "live_photo" : "live_text",
    msg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    msg_text: advisorMessage !== "" ? advisorMessage : "",
    receiver_type: "client",
    receiver_displayname: client_displayname,
    receiver_id: client_id,
    request_type: "live",
    response_time: "live",
    review_status: "0",
    sender_displayname: username,
    sender_id: id,
    sender_type: "advisor",
    status: "1",
    video_url: liveChatImageUrl !== null ? liveChatImageUrl : "",
  };

  const handleSendMessagetoClient = () => {
    try {
      if (session_status === "S") {
        if (advisorMessage.length === 0 && !liveChatImageUrl) {
        } else {
          const messagesSendingRef = ref(
            db,
            `/live_chats/live_session_${session_id}/messages`
          );

          push(messagesSendingRef, advisorMessageToFirebase)
            .then(() => {
              savingMessageToPayload();
              if (liveChatImageUrl) {
                dispatch(setLiveChatImageUrl(null));
              }
            })
            .catch((error) => {
              console.error("Error pushing message:", error);
            });
        }
      } else if (isReplyThisClient || chatHeadSelectedUser) {
        if (
          getReplyThisClientData?.response_time === "rush_audio" ||
          getReplyThisClientData?.response_time === "audio"
        ) {
          savingMessageToPayload();
          if (isReplyThisClient) {
            updateStatusByIds();
          }
        } else if (advisorTxtOrderMessage.length > 250) {
          savingMessageToPayload();
          updateStatusByIds();
        } else {
          dispatch(setIsSendingMessageIsSuccessfully());

          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Text Response Message Should Be At Least
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      250 Characters Long
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ));
        }
      }
    } catch (error) {
      dispatch(setIsSendingMessageIsSuccessfully(false)); // Ensure state is updated on error
    }
  };

  const updateStatusByIds = async () => {
    const filteredReviewID = chatBodyLiveFilteredData.filter(
      (item) => item.status === "0" && item?.sender_type === "advisor"
    );

    const reviewIds = filteredReviewID
      .map((item) => item.message_review_id)
      .join(",");

    // Combine single review ID with multiple review IDs
    const combinedReviewIds = [
      getReplyThisClientData?.message_review_id,
      reviewIds,
    ]
      .filter((id) => id) // Remove any falsy values (null, undefined, etc.)
      .join(",");

    const formData = new FormData();
    formData.append("message_sender_id", getReplyThisClientData?.sender_id);
    formData.append("message_review_id", combinedReviewIds);
    formData.append("user_id", id);
    formData.append("user_type", "advisor");

    try {
      const result = await updateStatusByMulipleReviewIds(formData);
      if (result?.data?.result == 1) {
        stoptAllModalHandlerTimer(getReplyThisClientData?.id);
      }
    } catch (error) {}
  };

  const savingMessageToPayload = async () => {
    const clientName =
      chatHeadSelectedUser?.sender_id === id
        ? chatHeadSelectedUser?.receiver_displayname
        : chatHeadSelectedUser?.sender_displayname;
    const clientid =
      chatHeadSelectedUser?.sender_id === id
        ? chatHeadSelectedUser?.receiver_id
        : chatHeadSelectedUser?.sender_id;

    const formData = new FormData();
    formData.append(
      "message_type",
      isReplyThisClient
        ? getReplyThisClientData?.response_time
        : session_status === "S" && liveChatImageUrl !== null
        ? "live_photo"
        : session_status === "S"
        ? "live_text"
        : "text"
    );
    formData.append("msg_date", moment().format("YYYY-MM-DD HH:mm:ss Z")); // default value for activity
    formData.append(
      "receiver_displayname",
      isReplyThisClient
        ? getReplyThisClientData?.sender_displayname
        : session_status === "S"
        ? client_displayname
        : clientName
    );
    formData.append(
      "receiver_id",
      isReplyThisClient
        ? getReplyThisClientData?.sender_id
        : session_status === "S"
        ? client_id
        : clientid
    );
    formData.append("client_dob", "");
    formData.append("receiver_type", "client");
    formData.append(
      "msg_text",
      advisorMessage !== ""
        ? advisorMessage
        : advisorTxtOrderMessage !== ""
        ? advisorTxtOrderMessage
        : getReplyThisClientData?.response_time
    );
    formData.append("sender_displayname", username);
    formData.append("email_notification_enabled", "1");
    formData.append("sender_id", id);
    formData.append("sender_type", "advisor");
    formData.append("status", "1");
    formData.append(
      "review_status",
      isReplyThisClient ? getReplyThisClientData?.review_status : "0"
    );
    formData.append(
      "video_url",
      liveChatImageUrl !== null
        ? liveChatImageUrl
        : localStorage.getItem("audioUrl")
        ? localStorage.getItem("audioUrl")
        : ""
    );
    formData.append("message_review_id", "w_".concat(makeMessageID(21)));
    formData.append(
      "client_message_review_id",
      isReplyThisClient ? getReplyThisClientData?.message_review_id : ""
    );
    formData.append(
      "first_message_id",
      isReplyThisClient ? getReplyThisClientData?.message_review_id : ""
    );
    formData.append(
      "order_type",
      isReplyThisClient ? "" : session_status === "S" ? "live" : ""
    );
    formData.append(
      "response_time",
      isReplyThisClient
        ? getRemainingTime(
            getReplyThisClientData?.msg_date,
            getReplyThisClientData?.message_type
          )
        : session_status === "S"
        ? "live"
        : ""
    );
    formData.append("user_id", id);
    formData.append("user_type", "advisor");
    formData.append("device_type", "web");
    formData.append("re_engagement", "0");

    const formDataToObject = (formData) => {
      const obj = {};
      formData.forEach((value, key) => {
        obj[key] = value;
      });
      return obj;
    };

    // Convert FormData to object
    const formDataObject = formDataToObject(formData);

    // Log the object to see the data
    try {
      const result = await saveChatPayload(formData);
      if (result?.data?.result == 1) {
        if (
          (isReplyThisClient || chatHeadSelectedUser) &&
          session_status !== "S"
        ) {
          updateStatusByIds();
          dispatch(setGetReplyThisClientData({}));
          dispatch(setChatHeadPagination(0));
          dispatch(setIsMessageRepliedSuccessfully(true));
          dispatch(setIsReplyThisClient(false));
          dispatch(setAdvisorTxtOrderMessage(""));
          dispatch(setIsSendingMessageIsSuccessfully(false));
        }
      }
      dispatch(setAdvisorTxtOrderMessage(""));
    } catch (error) {}
  };

  return {
    advisorMessageToFirebase,
    savingMessageToPayload,
    handleSendMessagetoClient,
  };
};
